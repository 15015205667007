import { UserAvatar } from '@carbon/icons-react';
import { Theme, ActionableNotification } from '@carbon/react';
import { useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReadyState } from 'react-use-websocket';

import { MY_COLLABORATIONS_PATH, MY_PROFILE_PATH } from 'constants/routes';
import { ChatWebSocketContext } from 'context/chat-websocket';
import { useProfileQuery } from 'hooks/account';
import { useLogout } from 'hooks/authentication';
import { useVersionQuery } from 'hooks/version';
import { getAbsoluteURL } from 'utils/utils';

import './MenuProfile.scss';

export const MenuProfile = () => {
  const profileQuery = useProfileQuery();
  const latestVersionQuery = useVersionQuery();
  const { t } = useTranslation();
  const logout = useLogout();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const websocketReady = useContext(ChatWebSocketContext)?.readyState === ReadyState.OPEN;

  const currentVersionTimestamp = import.meta.env.VITE_VERSION_TIMESTAMP;
  const currentVersion = import.meta.env.VITE_VERSION?.split('/').pop();

  const hasUpdate = Boolean(
    latestVersionQuery.data?.timestamp &&
      String(latestVersionQuery.data.timestamp) !== currentVersionTimestamp,
  );

  useEffect(() => {
    if (hasUpdate) {
      setIsMenuOpen(true);
    }
  }, [hasUpdate]);

  const onSignOutClick = () => {
    logout();

    document.title = 'SkyDeck.ai GenStudio';
  };

  const onMyProfileClick = () => {
    navigate(MY_PROFILE_PATH);
  };

  const onMyCollaborationsClick = () => {
    navigate(MY_COLLABORATIONS_PATH);
  };

  return (
    <div className='account'
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <div className='account__tooltip'>{t('Account')}</div>
      <div className='avatar-container'>
        {profileQuery.data && profileQuery.data.data.image_url ? (
          <img
            src={getAbsoluteURL(profileQuery.data.data.image_url)}
            alt='User Avatar'
            className='user-avatar'
            width={20}
            height={20}
          />
        ) : (
          <UserAvatar size={20} />
        )}
        {hasUpdate ? (
          <div className='notification-dot' />
        ) : (
          !websocketReady && <div className='notification-dot yellow' />
        )}
      </div>

      {isMenuOpen && ReactDOM.createPortal(
        <div className='menu-profile-portal'>
          <div className='menu-profile-portal-content'>
            {profileQuery.data && (
              <div className='profile-item-wrapper'>
                <span>{profileQuery.data.data.name}</span>
                <span>{profileQuery.data.data.email}</span>
              </div>
            )}

            <div className='profile-item-wrapper divider'>
              <span>{t('About GenStudio:')} {currentVersion ? `v${currentVersion}` : 'N/A'}</span>
              {hasUpdate && (
                <Theme theme='white' className='update-notification-wrapper'>
                  <ActionableNotification
                    className='update-notification'
                    kind='info'
                    title={t('Update available')}
                    subtitle={t(
                      'A new version of GenStudio is available. Please refresh the page to update.',
                    )}
                    actionButtonLabel={t('Refresh')}
                    hideCloseButton
                    onActionButtonClick={() => window.location.reload()}
                  />
                </Theme>
              )}
            </div>

            <button
              className='menu-item'
              onClick={onMyProfileClick}
            >
              {t('My Profile')}
            </button>

            <button
              className='menu-item'
              onClick={onMyCollaborationsClick}
            >
              {t('My Collaborations')}
            </button>

            <button
              className='menu-item'
              onClick={onSignOutClick}
            >
              {t('Sign out')}
            </button>
          </div>
        </div>,
        document.body,
      )}
    </div>
  );
};

export default MenuProfile;
